import React from "react"

// Component imports
import Layout from "../components/Layout"
import Section from "../components/Section"
import Text from "../components/Text"
import SEO from "../components/SEO"
import { Link } from "../components/Button"

const NotFound = () => {
  return (
    <Layout>
      <SEO title="404" nofollow noindex openGraph={undefined} />
      <Section className="flex flex-col items-center justify-center flex-1">
        <Text tag="h1" styleTo="h1" className="mb-4">
          404
        </Text>
        <Text className="mb-12">Page not found!</Text>
        <Link url="/">Go back home</Link>
      </Section>
    </Layout>
  )
}

export default NotFound
